<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex align-items-center mb-4">
                <div style="width: 60%">
                    <b-input-group>
                        <b-form-input
                            id="filter-input"
                            type="text"
                            placeholder="Buscar registros"
                            class="bg-background-input"
                            v-model="search"
                            @keydown.enter="searchData"
                            @input="search === '' && searchData()"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button
                                variant="background-input"
                                class="text-blue-active d-flex align-items-center"
                                @click="searchData()"
                            >
                                <feather type="search" size="1.1rem"></feather>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
                <div class="ml-auto">
                    <div
                        class="d-sm-flex mb-4 justify-content-end align-items-center"
                    >
                        <b-button
                            variant="primary"
                            size="md"
                            class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                            @click="addRegister"
                            v-b-popover.hover.top="'Presione alt+a o option+a'"
                            :disabled="!permisos.includes('template-crear')"
                        >
                            <feather
                                type="plus"
                                size="1rem"
                                class="mr-2 blue-active-text"
                            ></feather>
                            Nuevo Reporte
                        </b-button>
                        <!-- <b-dropdown
                            id="dropdown-divider"
                            text="Opciones"
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            :disabled="
                                !permisos.includes(
                                    'template-query-export'
                                ) &&
                                !permisos.includes(
                                    'template-query-export-and-email'
                                ) &&
                                !permisos.includes(
                                    'template-crud-export'
                                ) &&
                                !permisos.includes(
                                    'template-crud-import'
                                )
                            "
                        >
                            <template #button-content>
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <feather
                                        type="more-horizontal"
                                        size="1.5rem"
                                        class="text-secondary"
                                    ></feather>
                                </div>
                            </template>
                            <b-dropdown-item-button
                                v-for="(format, index) in formats"
                                :key="index"
                                @click="verifyFields(format.ext)"
                                :disabled="
                                    !permisos.includes(
                                        'template-query-export'
                                    )
                                "
                            >
                                {{ format.name }}
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                @click="$bvModal.show('email-modal')"
                                :disabled="
                                    !permisos.includes(
                                        'template-query-export-and-email'
                                    )
                                "
                                >Enviar informe por
                                Correo</b-dropdown-item-button
                            >
                        </b-dropdown> -->
                    </div>
                </div>
            </div>
        </b-col>
        <b-col cols="12" lg="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100 h-100">
                <div class="table-responsive mt-3">
                    <b-table
                        :fields="fields"
                        :items="templates"
                        :tbody-tr-class="rowClass"
                        borderless
                        responsive
                        head-variant="light"
                        class="mb-0 hover-table text-nowrap"
                    >
                        <template #cell(nombre)="data">
                            <span>{{ data.value }} </span>
                        </template>
                        <template #cell(acciones)="data">
                            <feather
                                v-if="permisos.includes('template-editar')"
                                type="edit"
                                role="button"
                                class="mr-2"
                                @click="
                                    $router.push({
                                        name: 'Editar reporte',
                                        params: {
                                            template_id: data.item.id
                                        }
                                    })
                                "
                                v-b-popover.hover.top="'Editar'"
                            ></feather>
                            <feather
                                v-else
                                type="trash-2"
                                role="button"
                                class="text-secondary"
                            ></feather>
                            <feather
                                v-if="permisos.includes('template-editar')"
                                type="trash"
                                role="button"
                                @click="showConfirm(data.item)"
                                v-b-popover.hover.top="'Eliminar'"
                            ></feather>
                            <feather
                                type="edit"
                                role="button"
                                class="mr-2 text-secondary"
                                v-else
                            ></feather>
                            <!-- <feather
                                type="eye"
                                role="button"
                                @click="
                                    $router.push({
                                        name: 'Ver compra mercancía',
                                        params: {
                                            compra_id: data.item.id
                                        }
                                    })
                                "
                                v-b-popover.hover.top="'Ver'"
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'template-export-individual-and-email'
                                    )
                                "
                                type="mail"
                                role="button"
                                class="ml-2"
                                v-b-popover.hover.top="'Enviar por correo'"
                                @click="sendSingleFactura(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="mail"
                                role="button"
                                class="ml-2 text-secondary"
                                v-b-popover.hover.top="'Enviar por correo'"
                            ></feather>
                            <feather
                                v-if="
                                    permisos.includes(
                                        'template-export-individual'
                                    )
                                "
                                type="printer"
                                role="button"
                                v-b-popover.hover.top="'Imprimir'"
                                class="ml-2"
                                @click="printCompra(data.item.id)"
                            ></feather>
                            <feather
                                v-else
                                type="printer"
                                role="button"
                                class="text-secondary ml-2"
                                v-b-popover.hover.top="'Imprimir'"
                            ></feather>
                            <feather
                                type="repeat"
                                role="button"
                                class="ml-2"
                                @click="
                                    $router.push({
                                        name: 'Crear devolucion proveedor',
                                        params: {
                                            compra_id: data.item.id
                                        }
                                    })
                                "
                                v-b-popover.hover.top="'Devolución en compra'"
                            ></feather> -->
                        </template>
                    </b-table>
                </div>
                <div class="p-3 d-flex align-items-center">
                    <b-form-group
                        label="Número de registros"
                        label-for="per-page-select"
                        label-size="sm"
                        label-class="fw-medium"
                        class="mb-0"
                    >
                        <b-form-select
                            id="per-page-select"
                            v-model="pagination.perPage"
                            :options="pagination.pageOptions"
                            @change="getReports(1, $event)"
                            size="sm"
                        ></b-form-select>
                    </b-form-group>
                    <div class="ml-auto">
                        <b-pagination
                            first-number
                            last-number
                            size="sm"
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            @input="getReports($event, pagination.perPage)"
                            align="fill"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div
                class="d-sm-flex mt-4 justify-content-between align-items-center"
            >
                <b-button
                    variant="primary"
                    size="md"
                    class="d-flex align-items-center mt-4 mt-sm-0 mr-4"
                    @click="addRegister"
                    v-b-popover.hover.top="'Presione alt+a o option+a'"
                    :disabled="!permisos.includes('template-crear')"
                >
                    <feather
                        type="plus"
                        size="1rem"
                        class="mr-2 blue-active-text"
                    ></feather>
                    Nuevo Reporte
                </b-button>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { mapGetters, mapState, mapActions } from 'vuex'
import { addListenerCommands, removeListenerCommands } from '@/utils/others'

const { API } = services

export default {
    name: 'MainTemplates',
    mounted() {
        addListenerCommands(this)
        this.getReports()
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    data: () => ({
        fields: [
            { key: 'nombre', label: 'Nombre' },
            { key: 'acciones', label: 'Acciones' }
        ],
        templates: [],
        pagination: {
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50, 100]
        },
        search: ''
    }),
    methods: {
        showLog(data) {
            console.log(data)
        },
        searchData() {
            this.getReports()
        },
        showConfirm({ id, nombre }) {
            this.$bvModal
                .msgBoxConfirm(
                    `Usted está apunto de eliminar el reporte "${nombre}", desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.deleteReport(id)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deleteReport(id) {
            API.POST({
                url: 'report/template/crud',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'DELETE',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.getReports()
                        this.$bvModal
                            .msgBoxOk(mensaje || 'Eliminado correctamente', {
                                title: 'Exito!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'success',
                                headerClass: 'modal-header'
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getReports(page = 1, limit = this.pagination.perPage) {
            API.POST({
                url: 'report/template/query',
                data: {
                    p_datajson: {
                        page,
                        term: this.search,
                        limit,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato: { content, totalContent, size, number },
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                            console.log(content)
                        this.templates = content
                        this.pagination = {
                            ...this.pagination,
                            totalRows: totalContent,
                            perPage: size,
                            currentPage: number
                        }
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status === 'active') return 'active'
        },
        addRegister() {
            this.$router.push(
                '/home/administrativo/herramientas/menu-informes/crear-reporte'
            )
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.form[key]
            return $dirty ? !$error : null
        },
        ...mapActions(['getImportFormat'])
    },
    computed: {
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'alt+a': this.addRegister,
                    'command+b': this.getReports
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'alt+a': this.addRegister,
                    'ctrl+b': this.getReports
                }
            } else {
                return {}
            }
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 45 }
            return json
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        ...mapGetters('usuario', [
            'getUserData',
            'getAuditoriaJson',
            'getPermisos'
        ]),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapState(['Theme'])
    }
}
</script>

<style></style>
